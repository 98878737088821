import React, { useEffect, useState,  useContext } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import { Button, Icon, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import reunificationService from '../../../services/reunification'
import AppContext from '../../../context/app'

export default function TabRouter(props) {
  const location = useLocation()
  const { institutionBuildingId } = useParams()
  const context = useContext(AppContext)
  const [value, setValue] = React.useState(0);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [shareEmail, setShareEmail] = useState(null)

  const tabs = [
    { label: 'General', to: `/emergencyManagement/reunification/general/${institutionBuildingId}` },
    { label: "Property", to: `/emergencyManagement/reunification/property/${institutionBuildingId}` },
    { label: "Utilities", to: `/emergencyManagement/reunification/utility/${institutionBuildingId}` },
    { label: "Community", to: `/emergencyManagement/reunification/community/${institutionBuildingId}` },
    { label: "Hazard", to: `/emergencyManagement/reunification/hazard/${institutionBuildingId}` },
    { label: "Assignment", to: `/emergencyManagement/reunification/assignment/${institutionBuildingId}` },
    { label: "Reunification", to: `/emergencyManagement/reunification/reunification/${institutionBuildingId}` },
    { label: "Threat", to: `/emergencyManagement/reunification/threat/${institutionBuildingId}` },
    { label: "Photo/Files", to: `/emergencyManagement/reunification/photo/${institutionBuildingId}` }
  ]


  useEffect( () => {
    let tabIndex = tabs.findIndex( (t) => location.pathname === t.to )
    setValue(tabIndex >=0 ? tabIndex : 0);
  },[location])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

   return (
    <div>
      <div className="studentReunification">
          <Box sx={{ width: '100%' }}>
              <Tabs 
                variant="scrollable" 
                scrollButtons="auto" 
                allowScrollButtonsMobile 
                value={value} 
                onChange={handleChange}
              >
                { tabs.map( (tab, index) => {
                    return (<Tab key={index} label={tab.label} component={Link} to={tab.to} /> )
                  })
                }
              </Tabs>
          </Box>
      </div>
    </div>
  );
}