import React, { useReducer, useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useDebouncedCallback } from 'use-debounce';
import { Paper, Button, Icon, TextField, MenuItem, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import Photo from './Photo'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'
import userService from '../../../services/user'


const SUBMODULE_ID = 1

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [siteVisit, setSiteVisit] = useState()
  const [siteVisitData, setSiteVisitData] = useReducer((state, newState) => ({ ...state, ...newState }),{ siteVisitDate: moment() });
  const [actionPlanOnly, setActionPlanOnly] = useState()

  useEffect( async () => {
    window.scroll(0,0)
    try {
      let users = await new userService().getInstitutionBuildingManagerUsers(institutionBuildingId)
      users.forEach( u => u.display = u.firstName ? `${u.firstName} ${u.lastName}` : u.email )
      setUsers(users)
      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'siteVisit', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData) {
        setSiteVisit(subModuleData)
        let thisSiteVisitData = JSON.parse(subModuleData.data)
        setSiteVisitData(thisSiteVisitData)
        setActionPlanOnly(context.user.role !== 'admin' && thisSiteVisitData.siteVisitByUserId && thisSiteVisitData.siteVisitByUserId !== context.user.userId)
      }

      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveSiteVisit = async (event) =>{
    event.preventDefault();
    try {
      siteVisitData.siteVisitDate = moment(siteVisitData.siteVisitDate).format('YYYY-MM-DD')

      //if action plans keys exist, mark the action plan complete and it has enough char to be useful
      if (Object.entries(siteVisitData).some(([key, value]) => key.toLowerCase().includes('actionplan') && value.length > 5))    
        siteVisitData.actionPlanComplete = true;

      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'siteVisit', subModules: [{ subModuleId: SUBMODULE_ID,  data: siteVisitData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/sitevisit/interior/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  const debouncedSave = useDebouncedCallback(
    async () => {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'siteVisit', subModules: [{ subModuleId: SUBMODULE_ID,  data: siteVisitData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Auto Save')
    },
    5000
  );
  useEffect( () => {
    if (!loading)
      debouncedSave()
  },[siteVisitData])

  return (
    <div className="siteVisit">
        <Paper className="paper">
            <form onSubmit={saveSiteVisit} method='post' encType="multipart/form-data">

            <div className="flex-row top-actions">
              { siteVisit ? <div className="flex-col">Last Updated: {moment(siteVisit.timeStamp).format('MM/DD/YYYY')} By {siteVisit.lastUpdatedByFirstName} {siteVisit.lastUpdatedByLastName}</div> : <div className="flex-col"/> }
              <div className="flex-col">
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
              </div>
            </div>

            <div className="flex-row">
                <div>Upon completetion of the Site Visit, please enter your comments and action plan within each section within 48 hours and click Notify Team</div>
            </div>

            <div className="flex-row">
                <div className="flex-row question">Site Visit Date</div>
                <div className='flex-row short'>
                   <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker disabled={actionPlanOnly} disableFuture={true} renderInput={(props) => <TextField required {...props} />} label="Date" value={siteVisitData.siteVisitDate} required onError={(e) => {siteVisitData.siteVisitDate = e === null; setSiteVisitData(siteVisitData)}} onChange={(e) => { if (!e) { return };  siteVisitData.siteVisitDate = e; setSiteVisitData(siteVisitData)}}/>
                   </LocalizationProvider>
                </div>
                <div className="flex-row">
                    <div className='short'>
                      <TextField disabled={context.user.role !== 'admin' && siteVisitData?.siteVisitByUserId && siteVisitData?.siteVisitByUserId !== context.user.userId} label="Completed By" select value={siteVisitData.siteVisitByUserId || ''} required onChange={(e) => { siteVisitData.siteVisitByUserId = e.target.value; setSiteVisitData(siteVisitData)}}>           
                      { users.map( (user, index) => {
                          return(
                            <MenuItem key={index} value={user.userId}>
                              {user.display}
                            </MenuItem>
                          )
                        }) 
                      }
                      </TextField>
                    </div>
                </div>
            </div>

              <div className="flex-row">
                <span className="question">Any issues with gates, fencing, open doors, etc. upon entering the campus?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.gateIssue === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.gateIssue = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.gateIssue == "No"} type="radio" required onChange={(e) => { siteVisitData.gateIssue = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.gateIssue == "NA"} type="radio" required onChange={(e) => { siteVisitData.gateIssue = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.gateComment || ''} onChange={(e) => { siteVisitData.gateComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.gateActionPlan || ''} onChange={(e) => { siteVisitData.gateActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={1}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Any issues with exterior cameras? (Not working, obstructed, etc.)</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.cameraIssue === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.cameraIssue = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.cameraIssue == "No"} type="radio" required onChange={(e) => { siteVisitData.cameraIssue = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.cameraIssue == "NA"} type="radio" required onChange={(e) => { siteVisitData.cameraIssue = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.cameraComment || ''} onChange={(e) => { siteVisitData.cameraComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.cameraActionPlan || ''} onChange={(e) => { siteVisitData.cameraActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={2}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">How was the exterior signage? (Not visible or clearly marked)</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.signageIssue === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.signageIssue = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.signageIssue == "No"} type="radio" required onChange={(e) => { siteVisitData.signageIssue = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.signageIssue == "NA"} type="radio" required onChange={(e) => { siteVisitData.signageIssue = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.signageComment || ''} onChange={(e) => { siteVisitData.signageComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.signageActionPlan || ''} onChange={(e) => { siteVisitData.signageActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={3}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">How were you vetted upon arrival?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Buzz-Intercom" value="Buzz-Intercom" control={<Radio required />} checked={siteVisitData.vetted === 'Buzz-Intercom'}  type="radio" required onChange={(e) => { siteVisitData.vetted = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="Raptor-ed in" value="Raptor-ed in" control={<Radio required />} checked={siteVisitData.vetted == "Raptor-ed in"} type="radio" required onChange={(e) => { siteVisitData.vetted = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="Not Vetted" value="Not Vetted" control={<Radio required />} checked={siteVisitData.vetted == "Not Vetted"} type="radio" required onChange={(e) => { siteVisitData.vetted = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.vetted == "NA"} type="radio" required onChange={(e) => { siteVisitData.vetted = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.vettedComment || ''} onChange={(e) => { siteVisitData.vettedComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.vettedActionPlan || ''} onChange={(e) => { siteVisitData.vettedActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={4}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">If you walked the school without escort, were you challenged in any way?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.arrivalChallenge === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.arrivalChallenge = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.arrivalChallenge == "No"} type="radio" required onChange={(e) => { siteVisitData.arrivalChallenge = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.arrivalChallenge == "NA"} type="radio" required onChange={(e) => { siteVisitData.arrivalChallenge = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" spellCheck={false} multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.arrivalChallengeComment || ''} onChange={(e) => { siteVisitData.arrivalChallengeComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.arrivalChallengeActionPlan || ''} onChange={(e) => { siteVisitData.arrivalChallengeActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={5}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Any other exterior safety & security items that warrant attention?</span>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 4000}} value={siteVisitData.otherComment || ''} onChange={(e) => { siteVisitData.otherComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.otherActionPlan || ''} onChange={(e) => { siteVisitData.otherActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={6}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>


            <div className="flex-row">
              <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
            </div>
          </form>
        </Paper>
    </div>
  )
}