import React from 'react';
import AppContext from '../context/app'

import moment from 'moment'
import { Paper, Fab, Button, IconButton, Select, MenuItem, TextField, FormLabel, FormControl, FormGroup, Checkbox} from '@material-ui/core';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import safetyEventService from '../services/safetyEvent';


const typeConfig = {
    "safetyMeeting": {
      subTypeOptions: [{ value: "Safety Meeting" }]
    },
    "drill": {
      subTypeOptions: [ 
        { value: "Fire/Evacuation Drill", "name": 'Fire/Evacuation Drill' },
        { value: "Hold Drill", "name": 'Hold Drill' },
        { value: "Shelter Drill", "name": 'Shelter Drill' },
        { value: "Lockdown Drill", "name": 'Lockdown Drill' },
        { value: "Lock-Out Drill", "name": 'Lock-Out Drill' },
        { value: "Shelter Drill", "name": 'Shelter Drill' },
        { value: "Re-Unification Drill", "name": 'Re-Unification Drill' },
        { value: "Other", "name": 'Other' }
      ]
    },
    "training": {
      subTypeOptions: [
        { value: "Active Shooter Preparedness Training", "name": 'Active Shooter Preparedness Training' },
        { value: "Threat Assessment Team Training", "name": 'Threat Assessment Team Training' },
        { value: "Bullying Prevention/Intervention Training", "name": 'Bullying Prevention/Intervention Training' },
        { value: "Non-Violent De-Escalation Training", "name": 'Non-Violent De-Escalation Training' },
        { value: "Emergency Communications Training", "name": 'Emergency Communications Training' },
        { value: "Special Events Security Training", "name": 'Special Events Security Training' },
        { value: "Safety Training for Aides/Monitors", "name": 'Safety Training for Aides/Monitors' },
        { value: "Crisis Leadership/Preparedness Training", "name": 'Crisis Leadership/Preparedness Training' },
        { value: "Other", "name": 'Other' }
      ]
    },
    "threatMeeting": {
      canAddAttendees: true,
      subTypeOptions: [{ value: "Threat Team Meeting" }]
    }
  }

class SafetyEventEntry extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { potentialAttendees: [], date: moment() }
    this.safetyEventService = new safetyEventService();    
    this.handleSaveSafetyEvent = this.handleSaveSafetyEvent.bind(this);
    this.handleDeleteSafetyEvent = this.handleDeleteSafetyEvent.bind(this);
    this.addAnotherOther = this.addAnotherOther.bind(this)
    this.toggleAttendees = this.toggleAttendees.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
  }

  bindPage(props){ 
    let history = props.history
    let potentialAttendees = props.potentialAttendees ?? []
    potentialAttendees.forEach( (a) => { 
      a.displayName = a.firstName ? `${a.firstName} ${a.lastName}` : a.email
      a.isAttending = true
    })
    //default types with 1 subtype to the type
    if (typeConfig[props.type].subTypeOptions.length === 1)
      this.setState({subType: typeConfig[props.type].subTypeOptions[0].value})
    this.setState({ typeConfig: typeConfig[props.type], potentialAttendees, history})
  }

  componentWillReceiveProps(nextProps){
    this.bindPage(nextProps)
  }

  componentWillMount () {
    this.bindPage(this.props)
  }

  render () {
    return (
      <div className="safetyEventEntry">
        { this.state.typeConfig ?
          <Paper className="history-paper" elevation={1}>
              <div className="history">
              <div className="flex-row title">
                  <h3 className="flex-col">{this.props.title}</h3>
                  { !this.state.addMode ?
                      <Fab className="flex-col no-print" size="small" color="primary" aria-label="add" onClick={ (e) => this.setState({addMode: true}) }>
                      <AddIcon />
                      </Fab>
                  : null }
              </div>

              { this.state.addMode ? 
                  <div className="section">
                      <form onSubmit={(e) => this.handleSaveSafetyEvent(e, this.props.type)} method='post' encType="multipart/form-data">
                      <div className="flex-row">
                          <div className="flex-col width-22 drill-input">
                            { this.state.typeConfig?.subTypeOptions?.length > 1 ?
                              <span className="subtype">
                                { !this.state.addOther ?
                                  <Select className="subtype" required name="subType" value={this.state.subType} onChange={this.handleChangeSelect} >
                                      <MenuItem  value="">Type</MenuItem>
                                      { 
                                          this.state.typeConfig.subTypeOptions.map( (subTypeOption, index) => {
                                              return (<MenuItem key={index} value={subTypeOption.value}>{subTypeOption.name}</MenuItem>)
                                          })
                                      }
                                  </Select>
                                : 
                                  <TextField label="Type" name="subType" type="text" maxLength="45" value={this.state.subType} required onChange={this.handleChange}/>
                                }
                              </span>
                            : null }
                          </div>
                          <div className="flex-col width-22 drill-input">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker required renderInput={(props) => <TextField {...props} />} label="Date" value={this.state.date} onError={(e) => { this.setState({ date: null})}} onChange={(e) => {if (!e || !e.isValid()) { return }; this.setState({ date: e}); }}/>
                            </LocalizationProvider>
                          </div>
                          <div className="flex-col width-22 drill-input">	
                            <TextField multiline maxRows={2} name="notes" type="text" inputProps={{maxLength: 2000}} value={this.state.notes} label="Notes/Actions Taken" onChange={this.handleChange} />
                          </div>
                          { this.state.typeConfig.canAddAttendees ?
                            <div className="flex-col width-22 drill-input">	
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Attendees</FormLabel>
                                <FormGroup>
                                  { 
                                    this.state.potentialAttendees.map( (user, index) => {
                                        return (
                                          <div key={index}>
                                            <Checkbox checked={user.isAttending} onClick={(e) => { user.isAttending = e.target.checked; this.setState({potentialAttendees: this.state.potentialAttendees}) }} />
                                            { user.addOther ?
                                              <TextField className="addOtherName" type="text" maxLength="60" value={user.other} onChange={(e) => { user.other = e.target.value.trim(); user.isAttending = !!user.other.length; this.setState({potentialAttendees: this.state.potentialAttendees}) }} />
                                            :
                                              <span>{user.displayName}</span>
                                            }   
                                          </div>
                                        )
                                    })
                                  }
                                
                                </FormGroup>
                              </FormControl>

                              <div className="addAnotherOther" onClick={this.addAnotherOther}><i className="fa fa-plus"> Add Another</i></div>
                            </div>
                          : null }

                          <div className="flex-col save">	
                              <Button type="submit" variant="outlined">Save</Button>
                          </div>
                      </div>
                      </form>
                  </div>
              : null }

              { this.state.history.length ? 
                  <div>
                      <div className="flex-row header section">
                          <div className="flex-row header">
                              <div className="flex-col header width-22">
                                  Type
                              </div>
                              <div className="flex-col header width-22">
                                  Date 
                              </div>
                              <div className="flex-col header width-22">	
                                  Notes/Actions Taken
                              </div>
                              <div className="flex-col header width-22">	
                                  
                              </div>
                          </div>
                      </div>

                      {
                      this.state.history.map( (historyItem, index) => {
                          return (
                              <div className="flex-row drill" key={index}>
                              <span className="flex-col width-22 drill-type">{historyItem.subType}</span>
                              <span className="flex-col width-22 drill-date">{moment.utc(historyItem.date).format('M/DD/YYYY h:mm a')}</span>
                              <span className="flex-col width-22 drill-notes" dangerouslySetInnerHTML={ { __html: historyItem.notes }}></span>
                              <span  className="flex-col width-22 hide-phone safetyEvent-view-attendee-link">
                                { this.state.typeConfig.canAddAttendees ?
                                    <span onClick={(e) => this.toggleAttendees(e, historyItem)}> 
                                      { historyItem.showAttendee ? 
                                        <div>
                                          <span className="attendee-toggle"><i className="fa fa-arrow-up"></i> Hide Attendees</span>
                                          { historyItem.attendees?.length ?
                                            <ul>
                                              {
                                                historyItem.attendees.map( (attendee, index) => {
                                                  return (
                                                    <li>{attendee.other || attendee.displayName}</li>
                                                  )
                                                })
                                              }
                                            </ul>
                                          : null }
                                        </div>
                                      : 
                                        <span className="attendee-toggle"><i className="fa fa-arrow-down"></i> View Attendees</span>
                                      }
                                    </span>
                                : null }
                              </span>
                              <span className="flex-col no-print hide-phone">
                              <IconButton aria-label="delete" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteSafetyEvent(historyItem) }} >
                                  <DeleteIcon />
                              </IconButton>
                              </span>
                              </div>
                          );
                      })
                      }
                  </div>
              : 
                  <div className={ this.state.addMode ? 'big-section' : null }>No Logs</div> 
              }
              </div>
          </Paper>
        : null }
      </div>
    )
  }

    
  handleChangeSelect(event) {
    if (event.target.value === 'Other')
      this.setState({ addOther: true });
    else      
      this.setState({[event.target.name]:event.target.value, addOther: false});
  }

  handleChange(event) {
    this.setState({[event.target.name]:event.target.value });
  }

  addAnotherOther(event) {
    let potentialAttendees = this.state.potentialAttendees
    potentialAttendees.push({ addOther: true, isAttending: false, other: '' })
    this.setState({potentialAttendees})
  }

  async handleSaveSafetyEvent(event, type){
    event.preventDefault();
    
    let attendees = this.state.potentialAttendees.filter( a => a.isAttending )
    try{
      let safetyEvent = {institutionBuildingId: this.props.institutionBuildingId, type, subType: this.state.subType, date: this.state.date.format('YYYY-MM-DD HH:mm'), notes: this.state.notes, attendees };
      await this.safetyEventService.saveSafetyEvent(safetyEvent)
      this.setState({ subType: '', date: moment(), notes: '', subTypeOther: null })
      this.setState({addMode: false })
      this.props.reload()
      this.context.toastSuccess('Event saved')
    }
    catch(err){
      console.log(err)
      this.context.toastError('Event could not save')
    }
  }

  async toggleAttendees(event, historyItem) {
    event.preventDefault();
    
    historyItem.showAttendee = !historyItem.showAttendee
    if (historyItem.showAttendee){
      let attendees = await this.safetyEventService.getSafetyEventAttendees(historyItem.emergencyManagementSafetyEventId)
      attendees.forEach( (a) => { 
        a.displayName = a.firstName ? `${a.firstName} ${a.lastName}` : a.email
      })
      historyItem.attendees = attendees
    }
    
    this.setState({ history: this.state.history })
  }

  async handleDeleteSafetyEvent(safetyEvent){
    try {
      safetyEvent = {institutionBuildingId: this.props.institutionBuildingId, emergencyManagementSafetyEventId: safetyEvent.emergencyManagementSafetyEventId };

      await this.safetyEventService.deleteSafetyEvent(safetyEvent)
      this.props.reload()
      this.context.toastSuccess('Event deleted')
    }
    catch(err){
      console.log(err)
      this.context.toastError('Event could not be deleted')
    }
  }

}

export default SafetyEventEntry
